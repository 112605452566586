.App {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
    gap: 40px;
    padding-top: 20px;

    h1 {
        margin: 0;

        a {
            display: flex;
            color: white;
            text-decoration: none;
            align-items: center;

            span {
                margin-left: 20px;
                margin-bottom: 20px;
            }

            img {
                height: 80px;
                width: 80px;
            }

            &:hover {
                text-decoration: underline;
                color: #eee;
                text-shadow: 4px 4px 2px #333;
            }

            &:active {
                color: #ccc;
                text-shadow: 5px 5px 3px #000;
            }
        }
    }
}

.App-logo {
    height: 30vmin;
    pointer-events: none;
    margin-bottom: 30px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
